import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Guardian",
      content: (
        <>
          <p>
            A guardian is a person appointed by the court who is authorized to
            make personal and/or financial decisions on behalf of a minor.
          </p>
        </>
      ),
    },
    {
      title: "Guardianship of the Estate",
      content: (
        <>
          <p>
            This is where a guardian is appointed to manage a child’s financial
            assets, such as an inheritance.
          </p>
        </>
      ),
    },
    {
      title: "Guardianship of the Person",
      content: (
        <>
          <p>
            This is where a guardian is appointed to see to the personal care of
            a child (providing food, clothing, shelter, safety, medical and
            dental care, education, and taking care of any special needs).
          </p>
        </>
      ),
    },
    {
      title: "Minor",
      content: (
        <>
          <p>A minor is a person under age 18.</p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Guardianship Lawyer in San Diego | Brierton, Jones & Jones"
        description="When you need a top San Diego guardianship lawyer, Brierton, Jones & Jones, LLP can help. We'll put our 30+ years of experience to work for you. Call today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  WHEN IT’S TIME TO STEP IN
                </div>
                <h1>San Diego Guardianship Lawyer</h1>
                <p>
                  Seeking guardianship over the legal responsibilities of a
                  child’s welfare can be complicated and stressful if you go it
                  alone. Our experienced guardianship lawyers can guide you
                  through the process, so it goes as smoothly and quickly as
                  possible.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/7.0 Guardianship/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>What Does It Mean To Have Legal Guardianship?</h2>
                <p>
                  When a child’s parents are physically or mentally unable to
                  make decisions for their child, the court can appoint a
                  guardian. A guardianship legally allows someone who is not a
                  child’s parent to step in. For example, the guardian can make
                  decisions regarding a child’s personal care, finances,
                  education, and medical treatment.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>Is Guardianship the Same as Adoption?</h2>
                <p>
                  No, they are not the same. Guardianship and adoption differ in
                  a few key ways:
                </p>

                <h3>Guardianship</h3>
                <ul className="mb-6 list-disc pl-6">
                  <li>
                    Parents retain their parental rights (such as asking for
                    reasonable contact or visitation)
                  </li>
                  <li>The court can end the guardianship</li>
                  <li>Guardians are supervised by the court</li>
                </ul>

                <h3>Adoption</h3>
                <ul className="mb-6 list-disc pl-6">
                  <li>The birth parents’ rights are permanently terminated</li>
                  <li>
                    The adoptive parents are considered the real parents for all
                    legal purposes
                  </li>
                  <li>
                    An adopted child inherits from their adoptive parents (just
                    like a birth child would)
                  </li>
                  <li>Adoptive families are not supervised by the court</li>
                </ul>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>Here are some of the common terms regarding guardianship:</p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={4} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="If you’re seeking to be appointed as a child’s guardian or need help managing their estate, our San Diego guardianship lawyers can help and answer all your questions. Contact us today." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/05 Guardianship.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/05 Guardianship.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
